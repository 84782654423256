import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe, faSpinner } from '@fortawesome/free-solid-svg-icons'; // Remove faPaypal from here
import { faPaypal } from '@fortawesome/free-brands-svg-icons'; // Keep this import
import './WyvernApp.css';
import './WyvernStripe.css';
import axios from 'axios';
import Success from './Success'; // Succes Component import
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import CheckoutForm from "./CheckoutForm";

const stripePromise = loadStripe("pk_test_51N1UhmLsD543qO3hyhaWcvEuxVx1kZFIRVPYDwUWsESq3cflc4borFLNxt9RoYHJZorZSPtNCZ8EScYhGnOH3Ryj00sOOevbJO");

function WyvernApp() {
  const [clientSecret, setClientSecret] = useState("");
  const [isStripePaymentView, setIsStripePaymentView] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isPaymentView, setIsPaymentView] = useState(false);
  const [isFFPayment, setFFPayment] = useState(false);
  const [paypalEmail, setPaypalEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState('');
  const [showSuccessPage, setShowSuccessPage] = useState(false);
  const [yourKey, setYourKey] = useState('');

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
  };

  const handlePayPalClick = () => {
    setIsPaymentView(true);
  };

  const handlePayPalffClick = () => {
    setFFPayment(true);
    setIsPaymentView(true);
  };

  const handleStripePaymentClick = () => {
    // Create PaymentIntent when Stripe is selected
    createPaymentIntent();
    setIsStripePaymentView(true);
    setIsPaymentView(true);
  };

  // Function to create PaymentIntent
  const createPaymentIntent = () => {
    fetch("https://senapay-final.vercel.app/api/create-payment-intent", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ items: [{ id: "xl-tshirt" }] }), // Modify as needed
    })
    .then((res) => res.json())
    .then((data) => setClientSecret(data.clientSecret));
  };

  const appearance = {
    theme: 'night',
    variables: {
      colorPrimary: '#ff284c',
    },
  };

  const options = {
    clientSecret,
    appearance,
  };

  const handleEmailChange = (e) => {
    setPaypalEmail(e.target.value);
  };

  const handleButtonClick = () => {
    if (selectedOption !== null) {
      window.open('https://www.paypal.com/donate/?hosted_button_id=3LMZRQ3FP3MMC', '_blank');
    }
  }

  const SUCCESS_MESSAGE = 'Payment verified successfully.';

  const handlePaymentConfirm = async () => {
    setIsLoading(true);
    setPaymentStatus('');
    console.log('Payment confirmation initiated.');
  
    // Sleep function using a Promise that resolves after a given time
    const sleep = (milliseconds) => {
      return new Promise(resolve => setTimeout(resolve, milliseconds));
    };
  
    try {
      let attempts = 0;
      let response;
  
      while (attempts < 12) {
        const parsedPrice = subscriptionOptions[selectedOption].price.replace('$', '').replace(',', '.');
        const creditsCount = subscriptionOptions[selectedOption].credits;
        console.log(`Attempt ${attempts + 1}: Verifying payment...`);

        try {
          response = await axios.post('https://senapay.net/api/verify-payment', {
            email: paypalEmail,
            amount: parsedPrice,
            credits: creditsCount
          });

          if (response.data.message === 'Payment verified successfully.') {
            // Handle successful payment verification
            setYourKey(response.data.key);
            setShowSuccessPage(true);
            console.log('Payment verified successfully.');
            return; // Exit the loop and function if payment is verified
          }
        } catch (error) {
          console.error('Error making request:', error);
        }
  
        // If payment is not verified, wait for 10 seconds before the next attempt
        await sleep(10000);
        attempts++;
      }
      
      setPaymentStatus('Oops! We couldn\'t find your payment >.<');
      // // If payment verification failed after all attempts
      // if (response && response.data.message !== 'Payment verified successfully.') {
      //   setPaymentStatus(response.data.message);
      //   console.log('Payment verification failed:', response.data.message);
      // }
    } catch (error) {
      console.error('Error verifying payment:', error);
      setPaymentStatus('We couldn\'t find your payment! Discord: baksa');
    } finally {
      setIsLoading(false);
      console.log('Payment confirmation process completed.');
    }
  };

  // useEffect(() => {
  //   if (isLoading) {
  //     const timer = setTimeout(() => {
  //       setIsLoading(false);
  //     }, 20000); // 20 seconds
  //     return () => clearTimeout(timer);
  //   }
  // }, [isLoading]);

  const subscriptionOptions = [
    { duration: '3 hours (280 Credits)', price: '$2,80', credits: '280' },
    { duration: '6 hours (400 Credits)', price: '$4,00', credits: '400' },
    { duration: '24 hours (800 Credits)', price: '$8,00', credits: '800' },
    { duration: '7 days (4K Credits)', price: '$40,00', credits: '4000' },
    { duration: '31 days (12K Credits)', price: '$120,00', credits: '12000' }
  ];

  if (showSuccessPage) {
    // Render the Success component when the payment is verified successfully
    return <Success keyToken={yourKey} />;
  }

  return (
    <div className="WyvernApp">
      <div className="night">
        <div className="shooting_star"></div>
        <div className="shooting_star"></div>
        <div className="shooting_star"></div>
        <div className="shooting_star"></div>
        <div className="shooting_star"></div>
        <div className="shooting_star"></div>
        <div className="shooting_star"></div>
        <div className="shooting_star"></div>
        <div className="shooting_star"></div>
        <div className="shooting_star"></div>
        <div className="shooting_star"></div>
        <div className="shooting_star"></div>
        <div className="shooting_star"></div>
        <div className="shooting_star"></div>
        <div className="shooting_star"></div>
        <div className="shooting_star"></div>
        <div className="shooting_star"></div>
        <div className="shooting_star"></div>
        <div className="shooting_star"></div>
        <div className="shooting_star"></div>
      </div>
      {/* <div className="gradient" /> */}
      {/* <div className="color-overlay"></div> */}
      <div className="logo"></div>
      <div className="logo-container">
        <img className='logo-icon' src="https://i.imgur.com/I9OFHRt.png"/>
        <div className='logo-text'>
          <div className="brand-name">WYVERN</div>
          <div className="pay-text">PAY</div>
        </div>
      </div>
      <div className="card-container">
        <div className="card">
          <div className="card-content">
            <div className="card-body">
              {isPaymentView ? (
                <div>
                    {isStripePaymentView ? (
                      clientSecret && (
                        <Elements options={options} stripe={stripePromise}>
                          <div style={{display: 'flex', justifyContent: 'center'}}>
                          <img src='http://localhost:3000/stripe-logo.png' style={{width: '70px'}}/>
                          </div>
                          <CheckoutForm />
                        </Elements>
                      )
                    ) : (
                      <div>
                        <div className="title-text">
                          Select <span className="separator">&gt;</span> Payment
                        </div>
                        <div className="selected-price">
                          {selectedOption !== null && (
                            <>
                              <div className="secondary-text">
                                <br></br>Product: Wyvern
                                <br></br>Order: {subscriptionOptions[selectedOption].duration}
                                <br></br>Price: {subscriptionOptions[selectedOption].price}
                              </div>
                            </>
                          )}
                        </div>
                        {isFFPayment ? (
                            <div>
                            <div className="payment-method">
                            1. Please send exactly:
                            <br />
                            <div className="price">{subscriptionOptions[selectedOption].price}usd</div>
                            to this email:
                          </div>
      
                          <input
                            type="text"
                            value="zaivessova@outlook.com"
                            style={{textAlign: 'center', outline: 'none'}}
                            readonly
                          /></div>
                          ):(
                            <div>
                            <div className="payment-method">
                            1. Please send exactly:
                            <br />
                            <div className="price">{subscriptionOptions[selectedOption].price}usd</div>
                            through this PayPal donation link:
                          </div>
      
                          <button className="donation-link-button" disabled={selectedOption === null} onClick={handleButtonClick}>
                          Donation Link
                          </button></div>
                        )}
      
                        <div className='payment-info'>2. Please Enter your PayPal email Here:</div>
                        <input
                          type="text"
                          placeholder="zaivessova@outlook.com"
                          value={paypalEmail}
                          onChange={handleEmailChange}
                        />
                        <div className='payment-info'>Why do we need your PayPal email?</div>
                        <div className='payment-info-secondary'>Senapay needs your PayPal email to locate your payment</div>
                        <button 
                          className={`payment-confirm ${isLoading ? 'pressed' : ''}`}
                          disabled={!paypalEmail || selectedOption === null || isLoading}
                          onClick={handlePaymentConfirm}>
                          {isLoading ? (<div style={{display: 'flex', flexDirection: 'row', alignContent:'center', justifyContent:'center'}}><FontAwesomeIcon icon={faSpinner} spin />
                            <p style={{fontSize: '12px', padding: 0, margin: 0, marginLeft: '7px'}}>SEARCHING... (Can take up to 2 mins)</p>
                          </div>) : 'I PAID'}
                        </button>
      
                        {
                          paymentStatus && (
                            <div className={`payment-status ${
                              paymentStatus === SUCCESS_MESSAGE ? 'payment-success' : 'payment-error'
                            }`}>
                              {paymentStatus}
                            </div>
                          )
                        }
      
                      </div>
                    )}
                </div>
              ) : (
                // Subscription view
                <div>
                  <div className="title-text">
                    Select Your Subscription
                  </div>
                  <p className="subscription-description">
                    Choose your Adventure!
                  </p>

                  <div className="server-button">
                    <FontAwesomeIcon icon={faGlobe} /> 
                    <div>Global Region Keys</div>
                  </div>

                  <div className="conversion">
                    Credit Conversion: $1 = 100 Credits
                  </div>

                  <div className="subscription-options">
                    {subscriptionOptions.map((option, index) => (
                      <div
                        key={index}
                        className={`subscription-option ${selectedOption === index ? 'selected' : ''}`}
                        onClick={() => handleOptionSelect(index)}
                      >
                        <input
                          type="radio"
                          name="subscription"
                          value={index}
                          checked={selectedOption === index}
                          onChange={() => handleOptionSelect(index)}
                        />
                        <div className="option-description">
                          <div>
                            {option.duration}
                          </div>
                          <div className="option-price">{option.price}</div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="selected-price">
                    {selectedOption !== null && (
                      <>
                        <div>Price: {subscriptionOptions[selectedOption].price}</div>
                      </>
                    )}
                  </div>
                  <div className="dual-button">
                    <button
                      className="pay-button"
                      disabled={true}
                    >
                      Pay via <FontAwesomeIcon icon={faPaypal} /> PayPal Donation
                    </button>
                    <button
                      className="pay-button"
                      disabled={selectedOption === null}
                      onClick={handlePayPalffClick}
                    >
                      Pay via <FontAwesomeIcon icon={faPaypal} /> PayPal F&F
                    </button>
                  </div>
                  <button
                      className="pay-button"
                      disabled={selectedOption === null}
                      onClick={() => window.location.href = 'https://safenetsolutions.store/products/wyvern-antivirus'}
                    >
                      Credit Card
                    </button>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className='brand-text'>Powered by SenaPay ©</div>
      </div>
    </div>
  );
}

export default WyvernApp;
